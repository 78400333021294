<template>
    <div>
        <LiefengModal title="详情页设置" :value="display" :fullscreen="true" :width="600" :height="600" @input="onChangeModal">
            <template v-slot:contentarea>
                <div class="jointly-detail-box container">
                    <div class="content">
                        <Form :model="formData" :label-colon="true" :label-width="100" :rules="formDataValidate" ref="form">
                            <FormItem class="form-item" label="详情页类型" prop="linkType">
                                <RadioGroup v-model="formData.linkType">
                                    <Radio label="1">通用</Radio>
                                    <Radio label="2">自定义页面</Radio>
                                    <Radio label="3">小程序</Radio>
                                </RadioGroup>
                            </FormItem>

                            <!-- 类型：通用 -->
                            <block v-show="formData.linkType === '1'">
                                <FormItem label="默认进入页面">
                                    <Select v-model="linkJson.defaultPage" style="width: 300px">
                                        <Option value="home">首页</Option>
                                        <Option value="commodity">商品</Option>
                                        <!-- <Option value="comment">评价</Option> -->
                                        <Option value="reserve">预约</Option>
                                        <Option value="information">资讯</Option>
                                        <Option value="activity">活动</Option>
                                    </Select>
                                </FormItem>

                                <div class="elem-detail-title-box">
                                    <p class="serial-number">1</p>
                                    <p class="name">首页</p>
                                </div>

                                <FormItem label="启用">
                                    <i-switch size="large" v-model="linkJson.home.isEnable">
                                        <span slot="1">启用</span>
                                        <span slot="0">禁用</span>
                                    </i-switch>
                                </FormItem>

                                <FormItem label="重命名">
                                    <Input style="width: 300px" v-model.trim="linkJson.home.rename" placeholder="请填写重命名"></Input>
                                </FormItem>

                                <div class="elem-detail-title-box">
                                    <p class="serial-number">2</p>
                                    <p class="name">商品</p>
                                </div>

                                <FormItem label="启用">
                                    <i-switch size="large" v-model="linkJson.commodity.isEnable">
                                        <span slot="1">启用</span>
                                        <span slot="0">禁用</span>
                                    </i-switch>
                                </FormItem>

                                <FormItem label="重命名">
                                    <Input style="width: 300px" v-model.trim="linkJson.commodity.rename" placeholder="请填写重命名"></Input>
                                </FormItem>

                                <FormItem label="关联店铺编码">
                                    <Input style="width: 300px" v-model.trim="linkJson.commodity.column" placeholder="请填写关联店铺编码"></Input>
                                </FormItem>

                                <!-- <div class="elem-detail-title-box">
                                    <p class="serial-number">3</p>
                                    <p class="name">评价</p>
                                </div>

                                <FormItem label="启用">
                                    <i-switch size="large" v-model="linkJson.comment.isEnable">
                                        <span slot="1">启用</span>
                                        <span slot="0">禁用</span>
                                    </i-switch>
                                </FormItem>

                                <FormItem label="重命名">
                                    <Input style="width:300px" v-model.trim="linkJson.comment.rename" placeholder="请填写重命名"></Input>
                                </FormItem> -->

                                <div class="elem-detail-title-box">
                                    <p class="serial-number">3</p>
                                    <p class="name">预约</p>
                                </div>

                                <FormItem label="启用">
                                    <i-switch size="large" v-model="linkJson.reserve.isEnable">
                                        <span slot="1">启用</span>
                                        <span slot="0">禁用</span>
                                    </i-switch>
                                </FormItem>

                                <FormItem label="重命名">
                                    <Input style="width: 300px" v-model.trim="linkJson.reserve.rename" placeholder="请填写重命名"></Input>
                                </FormItem>

                                <FormItem label="关联栏目">
                                    <Input style="width: 300px" type="textarea" v-model="linkJson.reserve.column.columnName" :value="linkJson.reserve.column ? linkJson.reserve.column.columnName : ''" placeholder="请填写栏目名称"></Input>
                                    <Input style="width: 300px" type="textarea" v-model="linkJson.reserve.column.columnCode" :value="linkJson.reserve.column ? linkJson.reserve.column.columnCode : ''" placeholder="请填写栏目编码"></Input>
                                    <!-- <Button type="primary" style="margin-left: 10px" @click="onOpenSelectColumn('reserve')">选择栏目</Button> -->
                                </FormItem>

                                <div class="elem-detail-title-box">
                                    <p class="serial-number">4</p>
                                    <p class="name">资讯</p>
                                </div>

                                <FormItem label="启用">
                                    <i-switch size="large" v-model="linkJson.information.isEnable">
                                        <span slot="1">启用</span>
                                        <span slot="0">禁用</span>
                                    </i-switch>
                                </FormItem>

                                <FormItem label="重命名">
                                    <Input style="width: 300px" v-model.trim="linkJson.information.rename" placeholder="请填写重命名"></Input>
                                </FormItem>

                                <FormItem label="关联栏目">
                                    <Input style="width: 300px" type="textarea" :value="linkJson.information.column ? linkJson.information.column.columnName : ''" v-model.trim="linkJson.information.column.columnName" placeholder="请填写栏目名称"></Input>
                                    <Input style="width: 300px" type="textarea" v-model="linkJson.information.column.columnCode" :value="linkJson.information.column ? linkJson.information.column.columnCode : ''" placeholder="请填写栏目编码"></Input>
                                    <!-- <Button type="primary" style="margin-left: 10px" @click="onOpenSelectColumn('information')">选择栏目</Button> -->
                                </FormItem>

                                <div class="elem-detail-title-box">
                                    <p class="serial-number">5</p>
                                    <p class="name">活动</p>
                                </div>

                                <FormItem label="启用">
                                    <i-switch size="large" v-model="linkJson.activity.isEnable">
                                        <span slot="1">启用</span>
                                        <span slot="0">禁用</span>
                                    </i-switch>
                                </FormItem>

                                <FormItem label="重命名">
                                    <Input style="width: 300px" v-model.trim="linkJson.activity.rename" placeholder="请填写重命名"></Input>
                                </FormItem>

                                <FormItem label="关联栏目">
                                    <Input style="width: 300px" type="textarea" v-model="linkJson.activity.column.columnName" :value="linkJson.activity.column ? linkJson.activity.column.columnName : ''" placeholder="请填写栏目名称"></Input>
                                    <Input style="width: 300px" type="textarea" v-model="linkJson.activity.column.columnCode" :value="linkJson.activity.column ? linkJson.activity.column.columnCode : ''" placeholder="请填写栏目编码"></Input>
                                    <!-- <Button type="primary" style="margin-left: 10px" @click="onOpenSelectColumn('activity')">选择栏目</Button> -->
                                </FormItem>

                                <div class="elem-detail-title-box">
                                    <p class="serial-number">6</p>
                                    <p class="name">公告</p>
                                </div>

                                <FormItem label="启用">
                                    <i-switch size="large" v-model="linkJson.announcement.isEnable">
                                        <span slot="1">启用</span>
                                        <span slot="0">禁用</span>
                                    </i-switch>
                                </FormItem>

                                <FormItem label="重命名">
                                    <Input style="width: 300px" v-model.trim="linkJson.announcement.rename" placeholder="请填写重命名"></Input>
                                </FormItem>

                                <FormItem label="公告内容">
                                    <Input style="width: 300px" type="textarea" v-model.trim="linkJson.announcement.content" placeholder="请填写公告内容"></Input>
                                </FormItem>
                            </block>

                            <!-- 类型：自定义页面 -->
                            <block v-show="formData.linkType === '2'">
                                <FormItem label="跳转链接" prop="columnId">
                                    <Input style="width: 300px" v-model.trim="linkJson.link" placeholder="请填写跳转链接"></Input>
                                </FormItem>
                            </block>

                            <!-- 类型：小程序 -->
                            <block v-show="formData.linkType === '3'">
                                <FormItem label="页面地址" prop="page">
                                    <Input style="width: 300px" v-model.trim="linkJson.pageAddr" placeholder="请填写页面地址"></Input>
                                </FormItem>
                                <FormItem label="APPID" prop="appid">
                                    <Input style="width: 300px" v-model.trim="linkJson.appId" placeholder="请填写APPID"></Input>
                                </FormItem>
                            </block>
                        </Form>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 10px" @click="onChangeModal(false)">取消</Button>
                <Button type="primary" @click="submit">确定</Button>
            </template>
        </LiefengModal>

        <!-- 关联栏目窗口 - start -->
        <LiefengModal title="关联栏目" :value="treeDisplay" :fullscreen="false" :width="600" height="100%" @input="treeDisplay = $event">
            <template v-slot:contentarea>
                <div class="container">
                    <div class="content">
                        <Tree ref="elem_tree" :data="tree_data" @on-select-change="onChangeTree"></Tree>
                    </div>
                </div>
            </template>
            <!-- <template v-slot:toolsbar>
                <Button type="info" style="margin-right:10px" @click="treeDisplay = false">取消</Button>
                <Button type="primary" @click="onSubmitChecked">确定</Button>
            </template> -->
        </LiefengModal>
        <!-- 关联栏目窗口 - end -->
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"

import Utils from "../utils/utils"

export default {
    components: {
        LiefengModal,
    },

    data() {
        return {
            display: false,
            treeDisplay: false,

            informationColumn: null,
            reserveColumn: null,
            activityColumn: null,

            formData: {
                linkType: "1",
            },

            linkJson: {
                home: {},
                commodity: {},
                comment: {},
                reserve: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                information: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                activity: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                announcement: {},
            },

            formDataValidate: {},

            detailId: null,

            tree_data: [],
        }
    },

    methods: {
        close() {
            this.display = false
            this.treeDisplay = false

            this.informationColumn = null
            this.reserveColumn = null
            this.activityColumn = null

            this.formData = {
                linkType: "1",
            }

            this.linkJson = {
                home: {},
                commodity: {},
                comment: {},
                reserve: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                information: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                activity: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                announcement: {},
            }

            this.formDataValidate = {}
            this.detailId = null
            this.tree_data = []
        },
        /**
         * 获取树形数据
         */
        getTreeData() {
            return new Promise(resolve => {
                const { userinfo } = parent.vue.loginInfo
                this.$post("/old/api/pc/information/getColumnMenuTree", {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: userinfo.orgCode,
                    staffId: userinfo.id,
                    adminType: userinfo.adminType,
                }).then(res => {
                    if (res.code != 200) {
                        return this.$Message.error(res.desc)
                    }

                    resolve(res.dataList)
                })
            })
        },

        onSubmitChecked() {
            const nodes = this.$refs.elem_tree.getCheckedNodes()
            const res = []

            for (let i = 0; i < nodes.length; i++) {
                let n = nodes[i]
                if (!n.children || n.children.length <= 0) {
                    res.push(n)
                }
            }

            this[this.activityColumnType + "Column"] = res

            this.treeDisplay = false
        },

        async onOpenSelectColumn(type) {
            this.activityColumnType = type
            if (!this.tree_data || this.tree_data.length <= 0) {
                this.$Spin.show()
                // 初始树形
                await this.getTreeData()
                    .then(res => {
                        this.tree_data = res
                    })
                    .finally(() => {
                        this.$Spin.hide()
                    })
            }
            this.treeDisplay = true
        },

        onChangeTree(evt) {
            const e = evt[0]

            // 是否存在栏目编码
            if (e.columnCode) {
                this.$set(this.linkJson[this.activityColumnType], "column", {
                    columnCode: e.columnCode,
                    columnName: e.title,
                })
                // 关闭栏目树
                this.treeDisplay = false
            } else {
                this.$Message.warning("当前栏目无编码")
            }
        },

        /**
         * 打开表单页面
         */
        open(id) {
            this.close()
            // 存在 ID 则获取详情
            id && this.getDetail(id)
            // 详情 ID
            this.detailId = id
            // 打开窗口
            this.onChangeModal(true)
        },

        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const fd = this.formData
                    const sl = this.linkJson
                    const pd = {
                        linkType: fd.linkType,
                        companyId: this.detailId,
                    }

                    switch (fd.linkType) {
                        case "1":
                            pd.linkJson = JSON.stringify(sl)
                            break
                        case "2":
                            pd.linkJson = JSON.stringify({
                                link: sl.link,
                            })
                            break
                        case "3":
                            pd.linkJson = JSON.stringify({
                                pageAddr: sl.pageAddr,
                                appId: sl.appId,
                            })
                            break
                    }

                    this.$post(`/gateway/api/sycompany/pc/company/companyConfigInfo`, { ...fd, ...pd }, { "Context-Type": "application/json" }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "配置成功",
                                background: true,
                            })
                            this.onChangeModal(false)
                        } else {
                            this.$Message.error({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                }
            })
        },

        getDetail(id) {
            this.$get("/gateway/api/sycompany/pc/company/getConfigInfo", {
                companyId: id,
            }).then(res => {
                if (res.code == 200) {
                    const data = res.data

                    if (data) {
                        this.formData = data
                        const lk = this.linkJson

                        Utils.eachObj(JSON.parse(data.linkJson), (k, v) => {
                            lk[k] = v
                        })
                    }
                } else {
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                }
            })
        },

        onChangeModal(flag) {
            // 改变弹窗显示状态
            this.display = flag
            // 如果为显示模式，刷新 Key
            if (flag === true) {
                this.page_key = this.$core.randomString()
            } else {
                // 清空表单
                Object.assign(this.$data, this.$options.data.call(this))
                // 重置表单字段 Form
                this.$refs.form.resetFields()
                // 父页面刷新列表
                this.$emit("resetBtn")
            }
        },

        getColumnText(column) {
            if (!column || column.length <= 0) {
                return ""
            }

            var texts = []

            column.forEach(e => {
                texts.push(e.title)
            })

            return texts.join(" , ")
        },
    },
}
</script>

<style lang="less">
.elem-detail-title-box {
    margin: 20px 20px 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 10px;

    .serial-number {
        width: 38px;
        height: 38px;
        font-size: 20px;
        color: #36414e;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d3d3d3;
        border-radius: 50%;
        font-weight: bold;
    }

    .name {
        font-size: 22px;
        margin-left: 16px;
        color: #36414e;
        font-weight: bold;
    }
}

.jointly-detail-box .form-item .ivu-form-item-content {
    margin-left: 0 !important;
}

.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        padding-top: 20px;
        flex: 1;
        height: 100%;
    }
}

/deep/ .validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}

/deep/ #modal_contentarea {
    overflow: inherit !important;
}
</style>
